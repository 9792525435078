import React, { useState } from "react";
import BEMHelper from "react-bem-helper";
import { Grid, Row, Col } from "react-bem-grid";
import axios from "axios";
import Actions from "./Actions";
import "./Mailform.scss";

// https://www.amcrou.ch/building-serverless-react-forms-using-react-hook-form-and-getformio

const classNames = new BEMHelper("mailform");

const Mailform = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });

    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = e => {
    e.preventDefault();

    const form = e.target;

    setServerState({ submitting: true });

    axios({
      method: "post",
      url: "https://getform.io/f/cf7775f8-76c5-43e3-b73a-1d6e521c81dc",
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(
          true,
          "Deine Nachricht wurde versendet, vielen Dank!",
          form
        );
      })
      .catch(r => {
        console.log(r);
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <div {...classNames()}>
      {serverState.submitting && <p>... Deine Nachricht wird gesendet ...</p>}

      {serverState.status ? (
        <p>{serverState.status.msg}</p>
      ) : (
        <form id="ajaxForm" onSubmit={handleOnSubmit} {...classNames("form")}>
          <Grid>
            <Row>
              <Col xs={12} sm={6}>
                <div {...classNames("field-group")}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Dein Name"
                    required
                    {...classNames("input")}
                  />
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div {...classNames("field-group")}>
                  <input
                    type="email"
                    name="email"
                    placeholder="Dein E-Mail Adresse"
                    required
                    {...classNames("input")}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div {...classNames("field-group")}>
                  <textarea
                    name="nachricht"
                    placeholder="Deine Nachricht"
                    rows="6"
                    required
                    {...classNames("textarea")}
                  ></textarea>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <button
                  {...classNames("button", null, ["button", "button--primary"])}
                  disabled={serverState.submitting}
                >
                  Abschicken
                </button>
              </Col>
            </Row>
          </Grid>
        </form>
      )}
    </div>
  );
};

Actions.propTypes = {};

export default Mailform;

// Status gesendet
//
// submitting: false
// status: {
//   ok: true,
//   msg: "Thanks!"
// }

// Status initisal
//
// submitting: false
// status: null
