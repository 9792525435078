import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import BEMHelper from "react-bem-helper";
import "./Image.scss";

const classNames = new BEMHelper("image");

const Image = ({
  isFluid = true,
  isRound = false,
  isBordered = false,
  isInline = false,
  imageData,
}) => {
  if (!imageData) {
    return <span>Picture not found</span>;
  }

  return (
    <div
      {...classNames(null, {
        bordered: isBordered,
        round: isRound,
        inline: isInline,
      })}
    >
      {isFluid ? <Img fluid={imageData} /> : <Img fixed={imageData} />}
    </div>
  );
};

Image.propTypes = {
  imageData: PropTypes.object,
  isRound: PropTypes.bool,
  isBordered: PropTypes.bool,
  isInline: PropTypes.bool,
  isFluid: PropTypes.bool,
};

export default Image;
