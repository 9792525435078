import React from "react";
import BEMHelper from "react-bem-helper";
import { Grid, Row, Col } from "react-bem-grid";
import FluidImage from "./FluidImage";
import Actions from "./Actions";
import { useStaticQuery, graphql } from "gatsby";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "./Books.scss";

const classNames = new BEMHelper({ name: "books", outputIsString: true });

const Books = () => {
  const data = useStaticQuery(graphql`
    query {
      Irgendwann_erwischt_es_jeden_Leseprobe: file(
        relativePath: {
          eq: "documents/Irgendwann_erwischt_es_jeden_Leseprobe.pdf"
        }
      ) {
        publicURL
      }
      Die_kleine_Pension_Kuestentraum_Leseprobe: file(
        relativePath: {
          eq: "documents/Die_kleine_Pension_Kuestentraum_Leseprobe.pdf"
        }
      ) {
        publicURL
      }
    }
  `);
  return (
    <div className={classNames()}>
      <Swiper modules={[Navigation, Pagination]} navigation>
        <SwiperSlide>
          <article className={classNames("item")}>
            <Grid>
              <Row xsCenter smStart mdMiddle>
                <Col xs={12} sm={5} md={5} mdOffset={1}>
                  <FluidImage
                    src="cover_winterzauber_in_der_pension_kuestentraum_rebecca-lehners.png"
                    alt="Cover des Buches 'Winterzauber in der Pension Küstentraum'"
                    additionalClasses={classNames("item-image")}
                  />
                </Col>
                <Col xs={12} sm={7} md={6}>
                  <div className={classNames("item-text")}>
                    <h2 className={classNames("header")}>
                      Die kleine Pension Küstentraum
                    </h2>
                    <p>
                      Ausgerechnet kurz vor einer wichtigen Präsentation macht
                      Sarah schlapp. Diagnose: Stressburnout. Der Arzt verordnet
                      ihr ein paar Wochen Ruhe, also quartiert sie sich bei
                      ihrer Schwester Julia in der Pension Küstentraum ein, wo
                      sie aufgewachsen ist. Doch schon auf der Zugfahrt von
                      Hamburg nach Appenkuhl wartet der nächste Schock auf sie,
                      denn dort begegnet sie Ole, der ebenfalls auf dem Weg an
                      die Ostsee ist. Obwohl die beiden eine gemeinsame
                      Vergangenheit haben, herrscht zwischen ihnen eine frostige
                      Stimmung. Zu allem Überfluss sind sie in der Pension auch
                      noch Zimmernachbarn und laufen sich ständig über den Weg.
                      Doch die ersten Schneeflocken und Oles tiefblaue Augen
                      bringen Sarahs harte Fassade langsam zum Bröckeln – und
                      schon bald befindet sie sich im größten Gefühlschaos …
                    </p>
                    {/* <Actions
                      additionalClasses={classNames("actions")}
                      actions={[
                        {
                          label: "Buch kaufen",
                          href:
                            "https://www.thalia.de/shop/home/artikeldetails/A1064176438",
                          internal: false,
                          style: "primary",
                        },
                        {
                          label: "Leseprobe",
                          href:
                            data.Die_kleine_Pension_Kuestentraum_Leseprobe
                              .publicURL,
                          internal: false,
                          style: "secondary",
                        },
                      ]}
                    /> */}
                  </div>
                </Col>
              </Row>
            </Grid>
          </article>
        </SwiperSlide>
        <SwiperSlide>
          <article className={classNames("item")}>
            <Grid>
              <Row xsCenter smStart mdMiddle>
                <Col xs={12} sm={5} md={5} mdOffset={1}>
                  <FluidImage
                    src="cover_die_kleine_pension_kuestentraum_rebecca-lehners.png"
                    alt="Cover des Buches 'Die kleine Pension Küstentraum'"
                    additionalClasses={classNames("item-image")}
                  />
                </Col>
                <Col xs={12} sm={7} md={6}>
                  <div className={classNames("item-text")}>
                    <h2 className={classNames("header")}>
                      Die kleine Pension Küstentraum
                    </h2>
                    <p>
                      Manchmal kann das Leben echt fies sein: Eben noch führte
                      Julia erfolgreich eine kleine Pension an der Ostsee und
                      war glücklich mit Clemens und im nächsten Moment verlässt
                      er sie und ihren gemeinsamen Sohn Leon für eine andere.
                      Als wäre das noch nicht schlimm genug, möchte der Ex auch
                      noch ausbezahlt werden! Doch auf Julias Freunde ist zum
                      Glück Verlass: Die halbe Dorfgemeinschaft versucht, ihr
                      mit gut gemeinten Ratschlägen und Kuppelversuchen zu
                      helfen. Und dann ist da noch der charmante Sebastian, mit
                      dem Julia eine Nacht verbracht hat. Seitdem geht der
                      smarte Anwalt ihr nicht mehr aus dem Kopf. Die beiden
                      begegnen sich allerdings schneller wieder, als Julia
                      gedacht hätte: Sebastian ist Clemens’ Anwalt.
                      Ausgerechnet! Für Julia steht plötzlich nicht nur ihre
                      Existenz auf dem Spiel, sondern auch ihr Herz …
                    </p>
                    <Actions
                      additionalClasses={classNames("actions")}
                      actions={[
                        {
                          label: "Buch kaufen",
                          href:
                            "https://www.thalia.de/shop/home/artikeldetails/A1064176438",
                          internal: false,
                          style: "primary",
                        },
                        {
                          label: "Leseprobe",
                          href:
                            data.Die_kleine_Pension_Kuestentraum_Leseprobe
                              .publicURL,
                          internal: false,
                          style: "secondary",
                        },
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            </Grid>
          </article>
        </SwiperSlide>
        <SwiperSlide>
          <article className={classNames("item")}>
            <Grid>
              <Row xsCenter smStart mdMiddle>
                <Col xs={12} sm={5} md={5} mdOffset={1}>
                  <FluidImage
                    src="cover_irgendwann-erwischt-es-jeden_rebecca-lehners.png"
                    alt="Cover des Buches 'Irgendwann erwischt es jeden'"
                    additionalClasses={classNames("item-image")}
                  />
                </Col>
                <Col xs={12} sm={7} md={6}>
                  <div className={classNames("item-text")}>
                    <h2 className={classNames("header")}>
                      Irgendwann erwischt es jeden
                    </h2>
                    <p>
                      Tränen wegen eines Mannes? Nicht mit Hanna! Die junge
                      Werbetexterin scheint gegen Liebeskummer immun und stürzt
                      sich nach der Trennung von ihrem Ex-Freund lieber in die
                      Arbeit. Dumm nur, dass sie für ihr neues Projekt
                      ausgerechnet mit ihrem unsympathischen Nachbarn
                      zusammenarbeiten muss, der zufällig Projektmanager bei der
                      Konkurrenz ist. Der bringt mit seiner arroganten Art nicht
                      nur Hannas Blut zum Kochen, sondern sorgt auch für
                      ordentlich Herzklopfen. Ein Zustand, der Hanna gar nicht
                      gefällt. Aber auch Sven trägt ein Geheimnis mit sich
                      herum, das ihn davon abhält, sich seine wahren Gefühle für
                      Hanna einzugestehen.
                    </p>
                    <Actions
                      additionalClasses={classNames("actions")}
                      actions={[
                        {
                          label: "Buch kaufen",
                          href:
                            "https://www.amazon.de/Irgendwann-erwischt-jeden-Rebecca-Lehners/dp/B08C7DYT68",
                          internal: false,
                          style: "primary",
                        },
                        {
                          label: "Leseprobe",
                          href:
                            data.Irgendwann_erwischt_es_jeden_Leseprobe
                              .publicURL,
                          internal: false,
                          style: "secondary",
                        },
                      ]}
                    />
                  </div>
                </Col>
              </Row>
            </Grid>
          </article>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Books;
