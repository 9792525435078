import React from "react";
import { graphql } from "gatsby";
import { Grid, Row, Col } from "react-bem-grid";
import DefaultPage from "../components/DefaultPage";
import SEO from "../components/Seo";
import Section from "../components/Section";
import SectionHeader from "../components/SectionHeader";
import Image from "../components/Image";
import Books from "../components/Books";
import Mailform from "../components/Mailform";
import "../sass/main.scss";

const IndexPage = ({ data }) => (
  <DefaultPage>
    <SEO title="Rebecca Lehners - Autorin und Werbetexterin" />
    <Section id="ueber-mich" style="intro">
      <Grid>
        <Row xsCenter mdStart mdMiddle mdBetween>
          <Col xsLast mdFirst xs={12} md={7}>
            <SectionHeader
              headline="Über mich"
              xsAlignment="center"
              lgAlignment="left"
            />
            <p>
              Aufgewachsen in Sachsen-Anhalt, hat mich die Liebe ins schöne
              Schleswig-Holstein verschlagen. Hier lebe ich mit meinem Mann und
              unseren zwei Rabauken in der Nähe von Kiel. Zum Glück bleibt mir
              trotz des manchmal chaotischen Familienalltags genug Zeit zum
              Lesen und Schreiben!
            </p>
            <p>
              Als Leserin begeistere ich mich besonders für moderne
              Liebesgeschichten. Irgendwann nach meiner ersten Elternzeit
              kribbelte es mir einfach in den Fingern und ich begann, meine ganz
              eigene Geschichte zu schreiben. Meine ersten Gehversuche startete
              ich bereits in der Grundschule mit dem Verfassen von Märchen.
              Heute stürze ich mich am liebsten in Wohlfühlromane mit Herz.
            </p>
          </Col>
          <Col xs={12} md={1}>
            <p></p>
          </Col>
          <Col xsFirst mdLast xs={10} sm={7} md={4}>
            <Image
              imageData={data.introImage.childImageSharp.fluid}
              isFluid={true}
              isRound={true}
              isBordered={true}
              isInline={true}
            />
          </Col>
        </Row>
      </Grid>
    </Section>
    <Section id="meine-buecher" color="grey" hasPadding={false}>
      <SectionHeader headline="Meine Bücher" xsAlignment="center" />
      <p style={{ textAlign: "center" }}>
        Hier findet Ihr meine bisher veröffentlichten Bücher:
      </p>
      <Books />
    </Section>
    <Section id="kontakt">
      <SectionHeader
        headline="Kontakt"
        xsAlignment="center"
        lgAlignment="left"
      />
      <p>
        Kontaktiere mich über dieses E-Mail Formular. Ob Feedback zu meinen
        Büchern, Tadel, Lob oder auch einfach nur so,... ich freue mich über
        Deine Nachricht.
      </p>
      <Mailform />
    </Section>
  </DefaultPage>
);

export default IndexPage;

export const indexPageQuery = graphql`
  query {
    introImage: file(relativePath: { eq: "images/rebecca-lehners.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
